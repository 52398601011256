import Link from "next/link"
import cx from "clsx"

import { ArrowLeft } from "ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import styles from "./back.module.scss"

interface BackProps {
  text: string
  link?: string
  onClick: () => void
  className?: string
  page?: "photo" | "favorites" | "order"
  isShortOnMobile?: boolean
}

const Back = ({ text, link, onClick, className, page, isShortOnMobile }: BackProps) => {
  if (link) {
    return (
      <Link href={link}>
        <a className={cx(styles["go-back"], className)}>
          <IconWrapper
            light={<ArrowLeft width={33} height={15} fill="#000" />}
            dark={<ArrowLeft width={33} height={15} fill="#fff" />}
          />
          <span
            className={cx(styles["go-back-text"], {
              [styles["compact"]]: page === "photo",
              [styles["mobile-hide"]]: isShortOnMobile,
            })}
          >
            {text}
          </span>
        </a>
      </Link>
    )
  }

  return (
    <button className={cx(styles["go-back"], className)} onClick={onClick}>
      <IconWrapper
        light={<ArrowLeft width={33} height={15} fill="#000" />}
        dark={<ArrowLeft width={33} height={15} fill="#fff" />}
      />
      <span
        className={cx(styles["go-back-text"], {
          [styles["compact"]]: page === "photo",
          [styles["compact-sm"]]: page === "order",
          [styles["mobile-hide"]]: isShortOnMobile,
        })}
      >
        {text}
      </span>
    </button>
  )
}

export { Back }
