const COLOR_BOOK = [
  {
    value: "brown",
    color: "#4b3627",
  },
  {
    value: "beige",
    color: "#d5be8d",
  },
  {
    value: "gray",
    color: "#939297",
  },
]

const COLOR_MINI_BOOK = [
  {
    value: "gray",
    color: "#f4f4f4",
  },
]

const BOOK_PRODUCT_DYNAMIC_SPREADS = "PHOTOBOOK_30X30"
const MINI_BOOK_PRODUCT_DYNAMIC_SPREADS = "MINIBOOK_23X23"
const BOOK_PRODUCT = "PHOTOBOOK_30X30_"
const MINI_BOOK_PRODUCT = "MINIBOOK_23X23_"
const CANVAS_PRODUCT = "CANVAS_30X42"
const PHOTO_SET_PRODUCT = "PHOTO_SET_10X15"

const MINI_BOOK_PRODUCT_CAPTION_MAX_LETTER = 28

const IS_WITH_GIFT = false // продукт с подарком в виде Набора фото

const WARNING_BANNER_DATE = "12/11/2023" // с 11 декабря 2023 года
const WARNING_BANNER_DATE_END = "01/01/2024" // заканчиваем показ баннера

export {
  BOOK_PRODUCT,
  BOOK_PRODUCT_DYNAMIC_SPREADS,
  CANVAS_PRODUCT,
  COLOR_BOOK,
  COLOR_MINI_BOOK,
  IS_WITH_GIFT,
  MINI_BOOK_PRODUCT,
  MINI_BOOK_PRODUCT_CAPTION_MAX_LETTER,
  MINI_BOOK_PRODUCT_DYNAMIC_SPREADS,
  PHOTO_SET_PRODUCT,
  WARNING_BANNER_DATE,
  WARNING_BANNER_DATE_END,
}
