import * as React from "react"
import cx from "clsx"

import { Spinner } from "@app/ui/spinner"

import styles from "./button.module.scss"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  className?: string
  skin?: "primary" | "secondary" | "inverted"
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, type = "button", skin = "primary", className, loading = false, disabled = false, ...props },
  ref
) {
  return (
    <button
      className={cx(
        styles["button"],
        {
          [styles["primary"]]: skin === "primary",
          [styles["secondary"]]: skin === "secondary",
          [styles["inverted"]]: skin === "inverted",
          [styles["loading"]]: loading,
          [styles["disabled"]]: disabled,
        },
        className
      )}
      type={type}
      ref={ref}
      {...props}
    >
      <Spinner className={styles["spinner"]}></Spinner>
      <span className={styles["inner"]}>{children}</span>
    </button>
  )
})

export { Button }
