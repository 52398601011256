const BUILD_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE = "photobook-build-layout-as-paid-service-visits"
const BUILD_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE = "photobook-editor-build-layout-as-paid-service-visits"

const BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE = "minibook-build-layout-as-paid-service-visits"
const BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE = "minibook-editor-build-layout-as-paid-service-visits"

export {
  BUILD_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE,
  BUILD_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE,
  BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE,
  BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE,
}
