import { useRouter } from "next/router"
import { useGallery } from "@app/contexts"
import { printEditorChangesIncrement } from "@app/features/statistics"
import { BOOK_PRODUCT, MINI_BOOK_PRODUCT, CANVAS_PRODUCT, PHOTO_SET_PRODUCT } from "@app/features/product/constants"

const PRODUCT_NAMES = {
  PHOTOBOOK: BOOK_PRODUCT,
  MINIBOOK: MINI_BOOK_PRODUCT,
  CANVAS: CANVAS_PRODUCT,
  PHOTOSET: PHOTO_SET_PRODUCT,
}

const printEditorChanges = () => {
  const { gallery } = useGallery()
  const router = useRouter()
  const product = router.pathname.split("/").pop().replace("-", "").toUpperCase()
  const { pages: productPages } = router.query
  const productName = `${PRODUCT_NAMES[product]}${productPages || ""}`
  const PRINT_EDITOR_CHANGES = `PRINT_EDITOR_CHANGES__${productName}__${gallery.id}`

  const sendEventEditorChange = async () => {
    // выполняем один раз на одном устройстве
    if (
      typeof window !== "undefined" &&
      !window?.localStorage?.getItem(PRINT_EDITOR_CHANGES) &&
      productName !== "undefined"
    ) {
      window.localStorage.setItem(PRINT_EDITOR_CHANGES, "true")

      await printEditorChangesIncrement(gallery.id, productName)
    }
  }

  return {
    sendEventEditorChange,
  }
}

export { printEditorChanges }
