import { api } from "@app/api"
import {
  BUILD_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE,
  BUILD_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE,
  BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE,
  BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE,
} from "./constants"

const sendStatisticsRequest = async (statisticKey: string, galleryId: string, data: any = {}) => {
  try {
    const response = await api().patch(`/statistics/${statisticKey}`, { gallery: galleryId, ...data })
    return response
  } catch (error) {
    console.log(error)
  }
}

// TODO: Нужен рефакторинг всего что снизу

const viewsCountIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/views`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const downloadsCountIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/downloads`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const visitsCountIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const sharesCountIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/galleries/${galleryId}/shares-count`)
  } catch (error) {
    console.error(error.response)
  }
}

const photoBookProductVisitsIncrement = async (galleryId: string, fromCustomerPhotos: boolean = false) => {
  try {
    await api().patch(`/statistics/photobook-visits`, { gallery: galleryId, fromCustomerPhotos: fromCustomerPhotos })
  } catch (error) {
    console.error(error.response)
  }
}

const photoBookEditorVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/photobook-editor-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const canvasProductVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/canvas-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const canvasEditorVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/canvas-editor-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const photoSetProductVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/photo-set-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const photoSetEditorVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/photo-set-editor-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const miniBookProductVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/minibook-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const miniBookEditorVisitsIncrement = async (galleryId: string) => {
  try {
    await api().patch(`/statistics/minibook-editor-visits`, { gallery: galleryId })
  } catch (error) {
    console.error(error.response)
  }
}

const orderContactsStepVisitsIncrement = async (
  galleryId: string,
  product: any,
  fromCustomerPhotos: boolean = false
) => {
  try {
    await api().patch(`/statistics/purchase-contacts-step-visits`, {
      gallery: galleryId,
      product: product,
      fromCustomerPhotos: fromCustomerPhotos,
    })
  } catch (error) {
    console.error(error.response)
  }
}

const orderDeliveryStepVisitsIncrement = async (galleryId: string, product: any) => {
  try {
    await api().patch(`/statistics/purchase-delivery-step-visits`, { gallery: galleryId, product: product })
  } catch (error) {
    console.error(error.response)
  }
}

const orderConfirmStepVisitsIncrement = async (galleryId: string, product: any) => {
  try {
    await api().patch(`/statistics/purchase-confirm-step-visits`, { gallery: galleryId, product: product })
  } catch (error) {
    console.error(error.response)
  }
}

const orderPaymentStepVisitsIncrement = async (
  galleryId: string,
  product: any,
  fromCustomerPhotos: boolean = false
) => {
  try {
    await api().patch(`/statistics/purchase-payment-step-visits`, {
      gallery: galleryId,
      product: product,
      fromCustomerPhotos: fromCustomerPhotos,
    })
  } catch (error) {
    console.error(error.response)
  }
}

const printEditorChangesIncrement = async (galleryId: string, product: any) => {
  try {
    await api().patch(`/statistics/print-editor-changes`, { gallery: galleryId, product: product })
  } catch (error) {
    console.error(error.response)
  }
}

// Обновляет количество переходов с кнопки "Соберем макет за вас"
const buildLayoutAsPaydServiceIncrement = (galleryId: string, from: "editor" | "product") => {
  if (from == "editor") return sendStatisticsRequest(BUILD_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE, galleryId)
  return sendStatisticsRequest(BUILD_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE, galleryId)
}

const buildLayoutAsPaydServiceMiniBookIncrement = (galleryId: string, from: "editor" | "product") => {
  if (from == "editor") return sendStatisticsRequest(BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_EDITOR_PAGE, galleryId)
  return sendStatisticsRequest(BUILD_MINI_BOOK_AS_PAID_SERVICE_FROM_PRODUCT_PAGE, galleryId)
}

export {
  viewsCountIncrement,
  downloadsCountIncrement,
  visitsCountIncrement,
  sharesCountIncrement,
  photoBookProductVisitsIncrement,
  photoBookEditorVisitsIncrement,
  canvasProductVisitsIncrement,
  canvasEditorVisitsIncrement,
  photoSetProductVisitsIncrement,
  photoSetEditorVisitsIncrement,
  miniBookProductVisitsIncrement,
  miniBookEditorVisitsIncrement,
  orderContactsStepVisitsIncrement,
  orderDeliveryStepVisitsIncrement,
  orderConfirmStepVisitsIncrement,
  orderPaymentStepVisitsIncrement,
  printEditorChangesIncrement,
  buildLayoutAsPaydServiceIncrement,
  buildLayoutAsPaydServiceMiniBookIncrement,
}
